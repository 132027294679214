import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styles/colors";
import { P } from "../styles/text-styles";

interface GearTabsetProps {
  activeTab: string;
}

export default function GearTabset(props: GearTabsetProps) {
  const tabs = [
    { title: "Snowboarding", destination: "/gear/snowboarding" },
    { title: "Skiing", destination: "/gear/skiing" },
  ];

  return (
    <Wrapper>
      {tabs.map((tab: any, index: number) => (
        <Link to={tab.destination} key={index}>
          <Nav $isActive={tab.title === props.activeTab}>{tab.title}</Nav>
        </Link>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;

  background-color: ${colors.gray2};
  border-radius: 10px;
`;

const Nav = styled(P)<{ $isActive?: boolean }>`
  color: ${props => (props.$isActive ? colors.white : colors.gray4)};
  background-color: ${props =>
    props.$isActive ? colors.gray3 : "transparent"};

  border-radius: 5px;
  padding: 4px 10px;
  margin: 4px;

  &:hover {
    filter: brightness(1.1);
  }
`;
