import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import { colors } from "../../styles/colors";
import { H5, P } from "../../styles/text-styles";

interface ListPanelProps {
  title: string;
  color?: string;
  items?: ListItemWithLink[];
  elementId?: string;
}

export interface ListItemWithLink {
  value: string;
  url: string | undefined;
  slug: boolean;
}

export default function ListPanel(props: ListPanelProps) {
  function getListItem(item: ListItemWithLink, index: number): any {
    if (item.url) {
      if (item.slug) {
        return (
          <StyledLink id={props.elementId || ""} to={item.url}>
            <P $color={colors.gray4}>
              {index + 1}. {item.value}
            </P>
          </StyledLink>
        );
      } else {
        return (
          <StyledA id={props.elementId || ""} href={item.url} target="_blank">
            <P $color={colors.gray4}>
              {index + 1}. {item.value}
            </P>
          </StyledA>
        );
      }
    }

    return (
      <P $color={colors.gray4}>
        {index + 1}. {item.value}
      </P>
    );
  }

  return (
    <Wrapper>
      <H5 $color={props.color ? props.color : colors.primary3}>
        {props.title}
      </H5>
      <List>
        {props.items?.map((item: ListItemWithLink, index: number) => (
          <ListItem key={index}>{getListItem(item, index)}</ListItem>
        ))}
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  box-sizing: border-box;
  padding: 36px;

  background-color: ${colors.gray2};
  border-radius: 10px;

  width: 100%;
`;

const List = styled.ul``;

const ListItem = styled.li`
  &:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  }
`;

const StyledLink = styled(Link)`
  :hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`;

const StyledA = styled.a`
  :hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`;
