import * as React from "react";
import styled from "styled-components";

import ListPanel, { ListItemWithLink } from "../components/panels/ListPanel";
import NumberPanel from "../components/panels/NumberPanel";
import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { Section } from "../styles/structures";
import { H3 } from "../styles/text-styles";

interface GearStatisticsProps {
  gearItemName: string;
  mainGearType?: string;
  color?: string;
  gearItemBrandRankData: GearBrandRankData[];
  gearItemModelNameRankData: GearItemRankData[];
  brandSites: BrandSite[];
}

interface GearItemRankData {
  fieldValue: string;
  totalCount: number;
  nodes: any;
}

interface GearBrandRankData {
  fieldValue: string;
  totalCount: number;
}

interface BrandSite {
  name: string;
  website: string;
  affiliateUrl: string;
}

export default function GearStatistics(props: GearStatisticsProps) {
  const {
    gearItemName,
    mainGearType,
    color,
    gearItemBrandRankData,
    gearItemModelNameRankData,
    brandSites,
  } = props;

  function getTopBrands(gearItems: GearBrandRankData[]): any {
    gearItems.sort((item1: GearBrandRankData, item2: GearBrandRankData) => {
      return item2.totalCount - item1.totalCount;
    });

    var totalCount = 0;
    gearItems.forEach(gearItem => {
      totalCount += gearItem.totalCount;
    });

    let topFive = gearItems.slice(0, 5);
    let topFiveListItems: ListItemWithLink[] = topFive.map(item => {
      let url =
        brandSites.find(x => x.name === item.fieldValue)?.affiliateUrl ||
        brandSites.find(x => x.name === item.fieldValue)?.website;

      return {
        value: item.fieldValue,
        url: url,
        slug: false,
      };
    });

    return {
      mostPopularBrand: gearItems[0].fieldValue,
      mostPopularUsage: (gearItems[0].totalCount / totalCount).toLocaleString(
        "en",
        {
          style: "percent",
        }
      ),
      topFive: topFiveListItems,
    };
  }

  function getTopGear(gearItems: GearItemRankData[]): ListItemWithLink[] {
    let listItems: any[] = [];

    gearItems.forEach(gear => {
      let numAthletes: number = 0;
      let maxYear: number = 0;
      let affiliateUrl: string = "";

      gear.nodes?.forEach((node: any) => {
        if (node.athlete != null) numAthletes += node.athlete.length;

        let currYear: number = Number(node.modelYear.slice(-4));
        if (currYear > maxYear) {
          maxYear = currYear;
          affiliateUrl = node.affiliateUrl;
        }
      });

      listItems.push({
        value: gear.fieldValue,
        url: affiliateUrl,
        slug: false,
        athleteCount: numAthletes,
      });
    });

    listItems.sort((item1: any, item2: any) => {
      return item2.athleteCount - item1.athleteCount;
    });

    return listItems.slice(0, 5);
  }

  const gearItemBrandStats = getTopBrands(gearItemBrandRankData);
  const gearItemModelNameStats = getTopGear(gearItemModelNameRankData);
  const triggerGearName = mainGearType
    ? mainGearType.toLowerCase() + "_" + gearItemName.toLowerCase()
    : gearItemName.toLowerCase();

  return (
    <Section>
      <ListHeadline>{gearItemName}</ListHeadline>
      <Wrapper>
        <NumberPanel
          title={gearItemBrandStats.mostPopularUsage}
          subheading={`of our riders use ${
            gearItemBrandStats.mostPopularBrand
          } ${gearItemName.toLocaleLowerCase()}`}
          color={color ? color : colors.primary2}
        />
        <ListPanel
          title="Top Brands"
          elementId={`top_brands_click_${triggerGearName}`}
          color={color ? color : colors.primary2}
          items={gearItemBrandStats.topFive}
        />
        <ListPanel
          title="Top Models"
          elementId={`top_models_click_${triggerGearName}`}
          color={color ? color : colors.primary2}
          items={gearItemModelNameStats}
        />
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const ListHeadline = styled(H3)`
  margin-bottom: 16px;
  text-align: center;

  ${breakpoints.md} {
    text-align: left;
  }
`;
