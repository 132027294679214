import { graphql } from "gatsby";
import * as React from "react";

import GearStatistics from "../components/GearStatistics";
import GearTabset from "../components/GearTabset";
import BannerWithEyebrow from "../components/banners/BannerWithEyebrow";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";
import { colors } from "../styles/colors";
import { Center } from "../styles/layouts";

export const blogQuery = graphql`
  query GearQuery($sport: String, $mainGearType: String) {
    mainByBrand: allContentfulGearItem(
      filter: { sport: { eq: $sport }, type: { eq: $mainGearType } }
    ) {
      group(field: brand___name) {
        totalCount
        fieldValue
      }
    }

    mainByModelName: allContentfulGearItem(
      filter: { sport: { eq: $sport }, type: { eq: $mainGearType } }
    ) {
      group(field: modelName) {
        fieldValue
        nodes {
          affiliateUrl
          modelYear
          athlete {
            id
          }
        }
      }
    }

    bindingsByBrand: allContentfulGearItem(
      filter: { sport: { eq: $sport }, type: { eq: "Binding" } }
    ) {
      group(field: brand___name) {
        totalCount
        fieldValue
      }
    }

    bindingsByModelName: allContentfulGearItem(
      filter: { sport: { eq: $sport }, type: { eq: "Binding" } }
    ) {
      group(field: modelName) {
        fieldValue
        nodes {
          affiliateUrl
          modelYear
          athlete {
            id
          }
        }
      }
    }

    bootsByBrand: allContentfulGearItem(
      filter: { sport: { eq: $sport }, type: { eq: "Boot" } }
    ) {
      group(field: brand___name) {
        totalCount
        fieldValue
      }
    }

    bootsByModelName: allContentfulGearItem(
      filter: { sport: { eq: $sport }, type: { eq: "Boot" } }
    ) {
      group(field: modelName) {
        fieldValue
        nodes {
          affiliateUrl
          modelYear
          athlete {
            id
          }
        }
      }
    }

    brandSites: allContentfulBrand {
      nodes {
        website
        name
        affiliateUrl
      }
    }
  }
`;

export default function Gear(props) {
  const { sport, athletes, mainGearType } = props.pageContext;

  const {
    mainByBrand,
    mainByModelName,
    bindingsByBrand,
    bindingsByModelName,
    bootsByBrand,
    bootsByModelName,
    brandSites,
  } = props.data;

  return (
    <Layout>
      <Seo
        title={`Popular ${mainGearType} Gear`}
        description={`Want to know what gear pro ${athletes} use? Come see what ${mainGearType.toLowerCase()}, boots, and bindings are most popular among the pros.`}
      />
      <BannerWithEyebrow
        eyebrow="See the most popular"
        headline={`${mainGearType} Gear`}
      />
      <Center>
        <GearTabset activeTab={sport} />
      </Center>
      <GearStatistics
        color={colors.primary2}
        gearItemName={`${mainGearType}s`}
        gearItemBrandRankData={mainByBrand.group}
        gearItemModelNameRankData={mainByModelName.group}
        brandSites={brandSites.nodes}
      />
      <GearStatistics
        color={colors.primary3}
        gearItemName="Bindings"
        mainGearType={mainGearType}
        gearItemBrandRankData={bindingsByBrand.group}
        gearItemModelNameRankData={bindingsByModelName.group}
        brandSites={brandSites.nodes}
      />
      <GearStatistics
        color={colors.primary4}
        gearItemName="Boots"
        mainGearType={mainGearType}
        gearItemBrandRankData={bootsByBrand.group}
        gearItemModelNameRankData={bootsByModelName.group}
        brandSites={brandSites.nodes}
      />
    </Layout>
  );
}
