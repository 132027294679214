import React from "react";
import styled from "styled-components";

import { colors } from "../../styles/colors";
import { H2, H5 } from "../../styles/text-styles";

interface NumberPanelProps {
  title: string;
  subheading: string;
  color?: string;
}

export default function NumberPanel(props: NumberPanelProps) {
  return (
    <Wrapper>
      <H2 $color={props.color ? props.color : colors.primary2}>
        {props.title}
      </H2>
      <H5>{props.subheading}</H5>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  align-items: center;
  justify-content: center;
  text-align: center;

  box-sizing: border-box;
  padding: 36px;

  background-color: ${colors.gray2};
  border-radius: 10px;

  width: 100%;
`;
